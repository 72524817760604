import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import MinecraftPricing from '../components/PricingPlans/MinecraftPricing';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import MinecraftDetailsImage from "../assets/images/jeux/minecraft-details.webp";
import MinecraftDetailsImage2 from "../assets/images/jeux/minecraft-details2.jpg";

const serviceImgStyle = {
    maxHeight: '500px',
    maxWidth: '100%',
    borderRadius: '10%',
 }

const ServiceDetails = () => (
    <Layout>
        <SEO title="Serveur Minecraft" /> 

        <Navbar />

        <PageBanner pageTitle="Serveur Minecraft | Tahiti" />

        <div className="services-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <h3>🎉 Lance ton serveur Minecraft ! 🎉</h3>
                            <p>Nous sommes ravis de vous présenter notre tout nouveau produit : l'hébergement de serveurs Minecraft ! Vous en avez rêvé, nous l'avons fait ! Plongez-vous dans l'univers passionnant de Minecraft et créez votre propre monde avec vos amis, grâce à notre solution d'hébergement haut de gamme, puissante et ultra-performante.</p>

                            <h6>🚀 Des serveurs puissants jusqu'à 4,7 GHz ! 🚀</h6>
                            <p>Chez FenuaHosting, nous mettons à votre disposition des serveurs dotés de processeurs ultra-puissants allant jusqu'à 4,7 GHz. Ainsi, vous bénéficiez d'une performance optimale pour une expérience de jeu inégalée. Dites adieu aux ralentissements et aux temps de chargement interminables, car avec nos serveurs, le plaisir de jouer est au rendez-vous !</p>
                        
                            <h6>⚡ Une latence ultra-faible pour des parties fluides ! ⚡</h6>
                            <p>Nous savons à quel point la latence peut être un véritable cauchemar pour les gamers. C'est pourquoi nous avons développé une infrastructure dédiée permettant de garantir une latence extrêmement faible, pour des parties plus fluides que jamais. Vous pourrez ainsi profiter de votre monde Minecraft sans aucune interruption ni décalage.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 services-details-image">
                        <img 
                            style={serviceImgStyle}
                            src={MinecraftDetailsImage} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 services-details-image">
                        <img 
                            style={serviceImgStyle}
                            src={MinecraftDetailsImage2} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <h6>🎮 Un panel de gestion intuitif pour un contrôle total ! 🎮</h6>
                            <p>Avec notre panel de gestion innovant, vous avez le contrôle absolu sur votre serveur Minecraft. Ajoutez des mods, gérez les sauvegardes, modifiez les paramètres et bien plus encore, le tout depuis une interface conviviale et facile à utiliser. Ne perdez plus de temps à chercher comment gérer votre serveur, FenuaHosting s'occupe de tout !</p>

                            <div className="services-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Est-il possible d'installer un mods custom ?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Absolument ! Avec votre panel utilisateur, vous pouvez installer des mods custom.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Quelles sont les versions prises en charge ?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Nous prenons en charge toutes les versions Minecraft. Java et Bedrock.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    Limite de slot ?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                                Non, nous ne limitons pas les slots (sauf la version gratuite à 2 joueurs). Vous pouvez vous-même modifier le nombre de slot acceptés par votre serveur. Seules les ressources sont limitées en fonction de votre abonnement.
                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <MinecraftPricing />

        <Footer />
    </Layout>
)

export default ServiceDetails;